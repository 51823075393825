.tabs {
  display: flex;
  width: 392px;
  flex-direction: column;
  flex-grow: 1;

  &__wrap {
    display: flex;
    width: 100%;
  }

  &__tab {
    height: 57px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0 none;
    outline: 0;
    background: #e6e9ed;
    font-size: 18px;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      background: #edeef1;
    }

    &--active {
      background: #f5f6f8;
      cursor: default;
      font-weight: 700;

      &:hover {
        background: #f5f6f8;
      }
    }

    &-icon {
      margin-right: 8px;
    }
  }

  &__contents,
  &__content {
    flex-grow: 1;
    display: flex;

    &--hidden {
      display: none;
    }
  }
}

@media (max-width: 400px) {
  .tabs {
    width: 100%;

    &__tab {
      &--active {
        font-weight: 400;
      }
    }
  }
}
