@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');
@import './scss/variables';
@import './scss/loader';

html {
  height: 100%;
  min-height: 660px;
}

body {
  min-height: 100%;
  margin: 0;
  position: relative;
  font-family: "Archivo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text;

  &.margin {
    min-height: calc(100% - 20px);
    padding: 10px;

    #root {
      top: 10px;
      bottom: 10px;
      right: 10px;
      left: 10px;
    }

    .app__back-button {
      margin-top: 20px;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  color: $text;
}

#root {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.button {
  background: $primary;
  border-radius: 8px;
  border: 0 none;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  padding: 8px 24px;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background: darken($primary, 5);
  }

  &:active {
    background: darken($primary, 10);
  }
}
