@import '../../../scss/variables';

.route-points {
  &__handler {
    cursor: move;
    padding: 10px 10px 10px 6px;
  }

  &__item {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    position: relative;
  }

  &__input {
    width: 100%;
    height: 48px;
    border: 0 none;
    background: #fff;
    padding: 12px;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 300;

    &:focus {
      outline: 0;
    }

    &-wrap {
      width: 100%;
    }
  }

  &__remove {
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
    border: none;
    pointer-events: none;

    img {
      width: 16px;
      height: 16px;
      opacity: 0.6;
      transition: opacity 0.3s ease;
    }

    &:hover img {
      opacity: 1;
    }

    &--visible {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__autocompeler {
    width: 322px !important;
  }
}

@media (max-width: 1023px) {
  .route-points {
    &__autocompeler {
      width: calc(100% - 70px) !important;
    }
  }

  body.margin .route-points {
    &__autocompeler {
      width: calc(100% - 90px) !important;
    }
  }
}
