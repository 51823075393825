@import '../../../scss/variables';

.find-station {
  display: flex;
  padding: 24px 24px 0;
  flex-direction: column;
  flex-grow: 1;

  &__input {
    background: #fff;
    border-radius: 8px;
    width: 100%;
    position: relative;
    z-index: 1001;

    &-field {
      width: 100%;
      box-sizing: border-box;
      height: 48px;
      line-height: 48px;
      font-size: 16px;
      font-weight: 300;
      border: 0 none;
      background: transparent;
      outline: 0;
      padding-left: 62px;
      padding-right: 90px;
    }

    &-clear {
      background: url(~/public/images/icons/common/close.svg) center center no-repeat;
      width: 24px;
      height: 24px;
      border: 0 none;
      position: absolute;
      top: 12px;
      right: 60px;
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    &-button {
      background: url(~/public/images/icons/common/arrow-right-white.svg) center center no-repeat;
      background-color: $primary;
      width: 48px;
      height: 48px;
      border: 0 none;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        background-color: darken($primary, 5);
      }

      &:active {
        background-color: darken($primary, 10);
      }
    }

    &::before {
      content: '';
      border-left: solid 2px #bfc6d1;
      position: absolute;
      top: 12px;
      bottom: 12px;
      left: 48px;
    }
  }

  &__localize-button {
    position: absolute;
    padding: 12px;
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.pac-container {
  width: 344px !important;
  box-shadow: none !important;
  padding: 20px 10px 10px;
  border-top: 0 none !important;
  border-radius: 0 0 8px 8px;
  margin-top: -10px !important;
}

@media (max-width: 1023px) {
  .pac-container {
    width: calc(100% - 48px) !important;
  }

  body.margin .pac-container {
    width: calc(100% - 68px) !important;
  }
}
