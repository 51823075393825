@import '../../../scss/variables';

.station-profile {
  min-width: 820px;
  max-width: 820px;
  background: #fff;
  display: flex;
  flex-grow: 1;
  border-radius: 4px 0 4px 0;
  flex-direction: column;
  padding: 40px;
  position: relative;
  z-index: 10;

  &__head {
    padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    width: 96px;
    margin-top: -15px;
  }

  &__partner {
    font-size: 16px;
    font-weight: 300;
  }

  &__address {
    width: 100%;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    padding-bottom: 3px;
    padding-left: 40px;
    box-sizing: border-box;
    max-width: 100%;
    overflow: hidden;

    strong {
      font-size: 32px;
      font-weight: 700;
      padding-bottom: 3px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 300;
    }
  }

  &__number {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    text-align: right;
    white-space: nowrap;
    padding-left: 10px;
    font-weight: 300;

    strong {
      font-size: 24px;
      font-weight: 700;
      padding-top: 3px;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 18px;
    font-weight: 300;
    margin-top: 8px;
    overflow: auto;
    margin-right: -40px;
    padding-right: 32px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bfc6d1;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  &__alert {
    width: 100%;
    display: flex;
    background: #f5f6f8;
    border-radius: 8px;
    padding: 11px 16px;
    margin-top: 24px;

    p {
      margin: 0;
      padding: 0;
    }

    img {
      padding-right: 10px;
    }
  }

  &__hours {
    display: flex;
    align-items: flex-start;
    font-size: 18px;
    margin-bottom: 16px;
    font-weight: 300;

    &--24h {
      color: #059669;
      font-weight: 700;
    }

    img {
      padding-right: 8px;
    }
  }

  &__full-address {
    display: flex;
    align-items: flex-start;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 16px;

    img {
      width: 24px;
      margin: 0 -2px;
      padding-right: 8px;
    }
  }

  &__tel {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 16px;

    a {
      color: $text;
      text-decoration: none;
    }

    img {
      padding-right: 8px;
    }
  }

  &__drive {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    &-button {
      font-size: 16px;
      font-weight: 700;
      display: flex;
      align-items: center;
      border: 0 none;
      border-radius: 4px;
      padding: 8px;
      background: #f5f6f8;
      cursor: pointer;
      color: $text;
      text-decoration: none;

      img {
        margin-left: 1px;
        width: 24px;
      }

      &:hover {
        background: #e6e8ea;
      }

      &:active {
        background: #e1e3e5;
      }
    }
  }

  &__features {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 22px;

    &-head {
      font-size: 18px;
      font-weight: 700;
      padding-bottom: 18px;
      padding-top: 26px;
      margin: 0;
    }

    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      font-size: 18px;
      font-weight: 300;

      li {
        margin: 0;
        width: calc(100%/3);
        display: flex;
        align-items: center;
        padding: 6px 12px 6px 0;
        box-sizing: border-box;

        img {
          width: 40px;
          height: 40px;
          margin-right: 16px;
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .station-profile {
    min-width: 100%;
    max-width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 5px 5px 8px 8px;

    &__head {
      padding-bottom: 16px;
    }

    &__logo {
      width: 40px;
      height: 40px;
      margin-top: 0;
    }

    &__address {
      font-size: 14px;
      padding-left: 16px;

      strong {
        font-size: 16px;
      }
    }

    &__number {
      font-size: 12px;
      padding-left: 10px;

      strong {
        font-size: 16px;
      }
    }

    &__content {
      font-size: 16px;
      margin-top: 8px;
      margin-right: -8px;
      padding-right: 8px;

      img {
        width: 16px;
        height: 16px;
      }

      &::-webkit-scrollbar {
        width: 5px;
      }
    }

    &__hours {
      font-size: 16px;
    }

    &__full-address {
      font-size: 16px;

      img {
        margin: 0;
      }
    }

    &__tel {
      font-size: 16px;
    }

    &__alert {
      margin-top: 8px;
    }

    &__drive {
      &-button {
        font-size: 12px;
        padding: 4px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    &__features {
      padding-top: 8px;

      &-head {
        font-size: 16px;
        padding-bottom: 8px;
      }

      &-list {
        font-size: 16px;

        li {
          padding: 6px 12px 6px 0;

          img {
            width: 32px;
            height: 32px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .station-profile {
    &__features {
      &-list {
        li {
          width: 50%;
        }
      }
    }
  }
}
