@import '../../../scss/variables';

.station-list {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-top: 54px;
  box-sizing: border-box;

  &__head {
    font-size: 16px;
    font-weight: 300;
    padding-bottom: 16px;
  }

  &__wrap {
    display: flex;
    flex-grow: 1;
    overflow: auto;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    margin-top: 4px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bfc6d1;
    }
  }
}
