@import '../../../scss/variables';

.plan-route {
  display: flex;
  padding: 24px;
  flex-direction: column;
  flex-grow: 1;

  &__wrapper {
    display: flex;
    flex-grow: 1;
    width: 100%;
    position: relative;
    flex-direction: column;
    overflow: hidden;
    padding-right: 24px;
    margin-right: -24px;
  }

  &__station-list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(100% - 80px);
    transition: top 0.6s ease;
    z-index: 2;
    margin-top: 80px;

    &--closed {
      top: 100%;
      z-index: 1;
      transition: top 0.4s ease;
    }
  }
}
