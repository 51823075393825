.app {
  position: relative;
  display: flex;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 1;

  &__content {
    background: #f5f6f8;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-grow: 1;

    &--margin {
      padding: 16px;
    }
  }

  &__back-button {
    background: none;
    display: flex;
    align-items: center;
    border: none;
    font-weight: 700;
    margin-bottom: 32px;
    cursor: pointer;

    img {
      transform: rotate(180deg);
      margin-right: 6px;
    }
  }
}

.right-col {
  display: flex;
  flex-grow: 1;
}

.left-col {
  display: flex;

  &__tabs-wrap {
    display: flex;
    flex-grow: 1;

    &--hidden {
      display: none;
    }
  }
}

@media (max-width: 1023px) {
  .app {
    &__content {
      flex-direction: column;

      &--margin {
        padding: 5px;
      }
    }
  }

  .left-col {
    height: 60%;
  }

  .right-col {
    height: 40%;
  }
}
