@import '../../../../scss/variables';

.station-list-item {
  background: #fff;
  padding: 8px;
  border-radius: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 16px;

  &__head {
    border-bottom: solid 1px #bfc6d1;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    width: 40px;
  }

  &__address {
    width: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    padding-bottom: 3px;
    padding-left: 16px;
    box-sizing: border-box;
    max-width: 100%;
    overflow: hidden;

    strong {
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 3px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    };

    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    };
  }

  &__number {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    text-align: right;
    white-space: nowrap;
    padding-left: 10px;

    strong {
      font-size: 16px;
      font-weight: 700;
      padding-top: 3px;
    };
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: 300;
    margin-top: 8px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  &__alert {
    width: 100%;
    display: flex;
    background: #f5f6f8;
    border-radius: 8px;
    padding: 11px 16px;
    margin-top: 8px;

    p {
      margin: 0;
      padding: 0;
    }

    img {
      padding-right: 10px;
    }
  }

  &__details-button {
    width: 100%;
    margin-top: 8px;
  }

  &__hours {
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: 300;

    &--24h {
      color: #059669;
      font-weight: 700;
    }

    img {
      margin-right: 8px;
    }
  }

  &__tel {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 8px;

    a {
      color: $text;
      text-decoration: none;
    }

    img {
      margin-right: 8px;
    }
  }

  &__distance {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
    justify-content: flex-end;
  }

  &__drive {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    &-button {
      font-size: 12px;
      font-weight: 700;
      display: flex;
      align-items: center;
      border: 0 none;
      border-radius: 4px;
      padding: 4px;
      background: #f5f6f8;
      cursor: pointer;
      color: $text;
      text-decoration: none;

      img {
        margin-left: 1px;
      }

      &:hover {
        background: #e6e8ea;
      }

      &:active {
        background: #e1e3e5;
      }
    }
  }
}
