.map {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;

  &__popup {
    width: 352px;
    position: absolute;
    margin-left: calc(-352px / 2);
    top: 0;
    left: 0;
    z-index: 1002;
    transform: translateY(-100%);
    overflow: hidden;
    user-select: none;
    pointer-events: none;

    * {
      user-select: none;
    }

    .station-list-item {
      margin: 0;
    }

    &-wrap {
      transition: transform 0.3s ease;
      transform: translateY(100%);

      &--active {
        transform: translateY(0);
        pointer-events: all;
      }
    }
  }
}
