@import '../../scss/variables';

.filters {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  &--absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 24px;
    height: 100%;
    z-index: 2;

    .filters__lists-wrap {
      max-height: 500px;
      bottom: 0;

      &--closed {
        max-height: 0;
      }
    }
  }

  &__head {
    font-size: 18px;
    display: flex;
    line-height: 25px;
    padding-top: 24px;
    font-weight: 300;
    align-items: center;
    justify-content: space-between;

    &-button {
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        opacity: 0.7;
      }
    }

    strong {
      padding-right: 5px;
    }

    &-arrow {
      width: 16px;
      height: 16px;
      margin-left: 8px;
      transition: transform 0.3s ease;

      &--reversed {
        transform: rotate(180deg);
      }
    }
  }

  &__clear-button {
    background: transparent;
    border: 0 none;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
    padding: 0;
    margin: 0;

    &:hover {
      opacity: 0.7;
    }
  }

  &__lists-wrap {
    position: absolute;
    top: 73px;
    left: 0;
    right: -24px;
    bottom: 24px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__slider {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    transition: top 0.5s ease;
    display: flex;
    flex-direction: column;

    &--closed {
      top: calc(-100% - 54px);
    }
  }

  &__slot {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: calc(100% + 54px);
  }

  &__lists {
    overflow: auto;
    flex-grow: 1;
    padding-right: 16px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bfc6d1;
    }
  }

  &__actions {
    height: 38px;
    padding: 24px 24px 0 0;
  }

  &__list {
    list-style: none;
    padding: 16px 0 32px;
    margin: 0;
    font-size: 16px;
    font-weight: 300;

    &:last-child {
      padding-bottom: 0;
    }

    li {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 8px 0;
    }
  }

  &__icon {
    margin-right: 16px;
  }

  &__checkbox {
    background: #fff;
    border: solid 2px #bfc6d1;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    display: flex;
    box-sizing: border-box;
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 16px;

    input {
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }

    &--active::after {
      content: '';
      background: #818ea4;
      width: 12px;
      height: 12px;
      display: block;
      border-radius: 2px;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
  }
}
