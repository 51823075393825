@import '../../scss/variables';

.route-points {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 16px;

  &__wrap {
    display: flex;
    width: 100%;
    flex-direction: column;
    max-height: 200px;
    overflow: auto;
  }

  &__button {
    width: 100%;
    margin-top: 24px;
  }

  &__add-point-button {
    background: transparent;
    border: 0 none;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-top: 8px;

    &[disabled] {
      opacity: 0.8;
      pointer-events: none;

      img {
        opacity: 0.4;
      }
    }

    &:hover {
      opacity: 0.7;
    }

    img {
      margin-right: 8px;
    }
  }
}

@media (max-width: 1023px) {
  .route-points {
    &__wrap {
      max-height: 112px;
    }
  }
}
